.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 20px; */
}

.container > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.container > div img {
  width: 300px; /* Adjust the width of the image as needed */
  height: auto;
  margin-bottom: 10px; /* Add some space between the image and the heading */
}

/* .body-button {
  border-radius: 0.5rem;
  padding: 0.5rem;
  background-color: #20a4a4;
  text-decoration: none;
  color: white
} */

.dashed-border {
  border: dashed 3px #08acec;
  padding-bottom: 30px;
  border-radius: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.emphasize {
  text-decoration: underline #20a4a4;
  font-style: italic;
}

.info-icon {
  position: relative;
  display: inline-block;
  margin-left: 5px; /* Adjust the margin as needed */
  cursor: pointer;
}

.info-icon::before {
  content: '\24d8'; /* Unicode character for circled lowercase i */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px; /* Adjust the font size as needed */
  color: #3498db; /* Conventional blue color */
  font-weight: bold; /* Make it bolder */
  margin-left: .5rem;
}





