.center-item-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.insurance-img {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 300px;
  height: auto;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.center-list {
  list-style: none;
  padding: 0;
  text-align: center;
}

.paragraph-price-container p {
  margin: 0.5rem; /* Remove default paragraph margin */
}

.original-price {
  text-decoration: line-through;
  color: #888; /* Original price color */
  transition: color 0.3s ease-in-out;
}

.discounted {
  text-decoration: line-through;
  color: #888; /* Adjust the color for the strikethrough */
}

.promotion-price-10 {
  color: #20a4a4; /* Promotion price color */
  font-weight: bold;
  margin-left: 0.5rem;
  animation: pulse 1s infinite; /* Add a subtle pulse animation */
}

.promotion-price-20 {
  color: #08acec; /* Promotion price color */
  font-weight: bold;
  margin-left: 0.5rem;
  animation: pulse 1s infinite; /* Add a subtle pulse animation */
}

.pricing-margin {
  padding-bottom: 0.5rem;
}

.bfr-price {
  color: #ec8d08; /* Promotion price color */
  font-weight: bold;
  margin-left: 0.5rem;
  animation: pulse 1s infinite; /* Add a subtle pulse animation */
}

.black-underline {
  text-decoration: underline;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
