.payment-img {
  width: 200px;
}

.taper-body-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.paragraph-container ul {
  display: inline-block;
  max-width: 80%;
  width: 100%;
  list-style-type: disc;
  padding-left: 1em;
  text-align: left;
}

.paragraph-container li {
  text-align: left;
}

.increase-font-size {
  font-size: 20px;
}

.shrink-font-size {
  font-size: 10px;
}
