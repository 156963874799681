.testimonials {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.testimonial {
  width: 300px;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.name {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 8px;
  text-decoration: underline;
  text-decoration-color: #20a4a4;
}

.content {
  font-style: italic;
  font-size: 16px;
  line-height: 1.4;
}

.name.odd {
  text-decoration: underline;
  text-decoration-color: #20a4a4;
}

.name.even {
  text-decoration: underline;
  text-decoration-color: #1074bc;
}
