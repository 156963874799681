.hamburger {
  display: none;
  position: relative;
  width: 64px;
  height: 64px;
  cursor: pointer;
}

.burger {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s linear;
}

.green-burger {
  z-index: 1;
  width: 32px;
  height: 32px;
}

.blue-burger {
  z-index: 2;
  opacity: 0; 
  width: 32px;
  height: 32px;
}

.hamburger:hover .green-burger {
  opacity: 0;
}

.hamburger:hover .blue-burger {
  opacity: 1;
}

@media (max-width: 1200px) {
  .hamburger {
    display: flex;
    flex-flow: column nowrap;
    height: 2rem;
    justify-content: space-around;
    width: 2rem;
    z-index: 10;
  }
}