* {
  font-family: "avenir", sans-serif;
}

body {
  background-color: #ebebeb;
}

.app-container {
  background-color: #FFFFFF;
  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 100%;
  margin: 2rem;
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 2rem 5rem;
}

h1 {
  text-align: center;
}

.change-img-size {
  max-width: 500px;
}

@media (min-width: 851px) {
  .app-container {
    margin-left: 10rem;
    margin-right: 10rem;
  }
}

@media (min-width: 851px) {
  .taper-body-info {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }
}

@media (max-width: 850px) {
  h1 {
    text-align: center;
  }
}

@media (max-width: 730px) {
  .app-container {
    padding: 1.5rem;
    margin: 1rem;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 20px;
  }

  @media (max-width: 480px) {
    h1 {
      font-size: 24px;
    }
  
    h2 {
      font-size: 20px;
    }
  
    h3 {
      font-size: 16px;
    }
  }
}