.steve-face {
  width: 200px;
}

.center-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bolded-titles {
  text-align: center;
}

/* .body-button {
  border-radius: 0.5rem;
  padding: 0.5rem;
  background-color: #20a4a4;
  text-decoration: none;
  color: white;
  margin: 0 auto;
} */

.body-button:hover {
  background-color: #08acec;
  transition: .5s ease;
  /* background-color: #1074bc; */
}