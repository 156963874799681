.social-media-container {
  display: flex;
  justify-content: center;
  gap: 10px; /* Adjust the value to control the padding between icons */
}

.social-media-icon {
  position: relative;
  width: 48px;
  height: 48px;
  overflow: hidden;
}

.social-media-icon img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity .5s ease;
}

.social-media-icon img:first-child {
  z-index: 1; /* Set the z-index of the green image to a higher value */
}

.social-media-icon img:last-child {
  z-index: 2; /* Set the z-index of the blue image to a higher value */
  opacity: 0; /* Initially hide the blue image */
}

.social-media-icon:hover img:first-child {
  opacity: 0; /* Hide the green image on hover */
}

.social-media-icon:hover img:last-child {
  opacity: 1; /* Show the blue image on hover */
}

@media (max-width: 1050px) {
  .social-media-container {
    justify-content: space-evenly;
    padding-top: 1rem;
  }
}