.prefooter {
  bottom: 0;
  border-top: 1.5px solid #1074bc;
  position: static;
  left: 0;
  margin-top: 1.5rem;
  right: 0;
  padding-top: 1.5rem;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 5px;
  align-items: center;
  grid-template-areas: "pf1 pf2";
}

.pf1 {
  grid-area: pf1;
}

.pf2 {
  position:relative;
  grid-area: pf2;
}

.location-container {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.address-container {
  display: flex;
  flex-direction: column;
}

.location-icon {
  height: 24px;
  width: 24px;
}

.bellator-fitness-icon {
  height: 105px;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 105px;
}

.address-and-logo-container {
  display: flex;
  flex-direction: row;
}

@media (max-width: 1050px) {
  .address-and-logo-container {
    align-items: center;
    justify-content: space-around;
  }
  
  .prefooter {
    grid-template-areas:
      "pf1"
      "pf2";
  }
}