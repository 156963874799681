.home-body-image {
  border-radius: 0.5rem;
  height: auto;
  margin-bottom: 2rem;
  margin-top: 2rem;
  max-width: 500px;
  width: 100%;
}

.body-button {
  background-color: #20a4a4;
  border-radius: 0.5rem;
  color: white;
  margin: 0 auto;
  padding: 0.5rem;
  text-decoration: none;
}

.center-item-container {
  display: flex;
  justify-content: center;
}

.underline-text {
  font-style: italic;
  font-weight: bold;
  text-decoration: underline #20a4a4;
}

.-container {
  text-align: center;
}

img {
  border-radius: 0.5rem;
}

@media (max-width: 850px) {
  .underline-text {
    text-align: center;
  }
}