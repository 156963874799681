.payment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.payment-component a {
  color: #0070ba;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding-bottom: 2rem;
}

.payment-component a:hover {
  text-decoration: none;
}

/* This will apply the transition effect to the button */
.payment-component button {
  transition: 0.5s ease;
}

/* This will change the button's background when the parent anchor is hovered */
.payment-component a:hover button {
  background-color: #08acec;
}
