.additional-footer {
  padding: 14px 0;
  display: flex;
  justify-content: flex-end; /* Pushes the content to the far right */
}

.additional-footer ul {
  list-style-type: none; /* Removes the bullet points */
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column; /* Stacks the list items on top of each other */
  gap: 0.5rem; /* Space between the list items */
}

.additional-footer li {
  display: block;
}

.additional-footer a {
  color: black; /* Makes the text black */
  text-decoration: underline #20a4a4;
  font-weight: bold;
}

.additional-footer a:hover {
  color: #08acec; /* Changes text color on hover */
  text-decoration: underline #08acec; /* Changes underline color on hover */
  transition: color 0.3s, text-decoration 0.3s; /* Smoothens the hover effects for both properties */
}

/* Media query for screens with a width of 1050px or less */
@media (max-width: 1050px) {
  .additional-footer {
    justify-content: center; /* Center the content */
    text-align: center; /* Center the text within the li elements */
  }
}