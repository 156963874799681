.footer {
  color: #1074BC;
  text-align: center;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: relative;
  bottom: 0;
  left: 0;
}

.footer p {
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
}