.sidebar-wrapper {
  position: relative;
  width: 100%;
}

.sidebar {
  background-color: #FFFFFF;
  border: 1px solid #C8C8C8;
  border-radius: 10px;
  position: absolute;
  width: 300px;
  top: 2rem; /* Adding this to push the sidebar down */
}

.sidebar li {
  list-style-type: none;
}

.sidebar li:not(:first-child) {
  margin-top: 1rem;
}

.sidebar a {
  color: #1074BC;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: normal;
  text-decoration: none;
}

.sidebar a:hover {
  animation: pulse 1s linear infinite;
  color: #08ACEC;
  transition: .5s ease;
}
