.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-content {
  display: flex;
  justify-content: center;
}

.left-section {
  margin-right: 2rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
  font-size: 14px;
}

input,
textarea {
  width: 100%;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
}

button {
  padding: 0.5rem 1rem;
  font-size: 16px;
  background-color: #20a4a4;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  /* Center the button horizontally */
  margin-left: auto;
  margin-right: auto;
  display: block;
}

button:hover {
  background-color: #08acec;
  transition: 0.5s ease;
}

form {
  border: solid;
  border-color: #1074bc;
  border-radius: 1rem;
  padding-left: 2rem;
  padding-right: 3rem;
  padding-bottom: 2rem;
  margin-left: 56px;
  margin-right: 56px;
}

@media (max-width: 730px) {
  form {
    margin-left: 0;
    margin-right: 0;
  }
}
